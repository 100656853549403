import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AutoComplete, Icon, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { useOrdersSearch, useDebounce } from "../../../hooks";
import {
  getOrdersSearchData,
  setRequestUrl,
  setResults,
  setSearchErrors,
  setSearchValue,
  setSelectedFilter,
} from "../../../store/slices/orders";
import { FormField } from "components";
import { SearchBar } from "../../ContainerComponents/SearchBar";
import { getErrorMessage } from "helpers";
import { productTypesNamesEnum } from "../../../constants";
import CreateOrder from "../../../containers/Orders/Orders/CreateOrder";

const {
  Container,
  TitleWrapper,
  IconWrapper,
  Title,
  SearchWrapper,
  SearchSelect,
  Autocomplete,
  ActionButton,
} = SearchBar;

export const OrdersSearchBar = () => {
  const [showModal, setShowModal] = useState(false);
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { selectedFilter, searchValue, results, errors } = useSelector(
    (state) => state.orders.searchData
  );
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const {
    searchFilterOptions,
    DEFAULT_VALUE,
    ordersSearchSchema,
    getSearchRequest,
    getRedirectPath,
  } = useOrdersSearch();

  function formatParameter(parameter = "", prefix = "") {
    const formatedPrefix = prefix.toLowerCase();
    const formatedParameter = parameter.toLowerCase();
    const result = formatedParameter.replace(formatedPrefix, "");
    return result;
  }

  const renderAutocompleteOptions = {
    ORDERS_CARD: (data) => (
      <AutoComplete.Option
        key={data.code}
        value={data?.code.toString()}
        style={{
          margin: "4px 8px",
        }}
      >
        <span>
          <span style={{ fontFamily: "NoirPro medium" }}>
            {data.companyName || data?.contactName}
          </span>
        </span>
        <br />
        <span>{productTypesNamesEnum[data.type] || data?.type}</span>
        <br />
        {data.documentNumber && (
          <>
            <span>
              <NumberFormat
                format="##.###.###/####-##"
                displayType="text"
                value={data.documentNumber}
              />
            </span>
            <br />
          </>
        )}
        <span>{data.email}</span>
        <br />
        <span>
          <NumberFormat
            format="(##) #########"
            displayType="text"
            value={data?.phoneNumber}
          />
        </span>
      </AutoComplete.Option>
    ),
    NO_RESULTS_FOUND: (data) => (
      <AutoComplete.Option
        key={data.description}
        value={data.description}
        style={{
          margin: "4px 8px",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <span
          style={{
            margin: "0 auto",
            padding: "16px 0",
          }}
        >
          {translation("customer.noResultsFound")}
        </span>
      </AutoComplete.Option>
    ),
  };

  const dataSource = results.map((result) => {
    return result
      ? renderAutocompleteOptions.ORDERS_CARD(result)
      : renderAutocompleteOptions.NO_RESULTS_FOUND(result);
  });

  const handleSelectFilter = (filter) => {
    dispatch(setSelectedFilter(filter));
    dispatch(setRequestUrl(""));
    dispatch(setSearchValue(""));
    dispatch(setResults([]));
    dispatch(setSearchErrors([]));
  };

  const handleNewOrder = () => {
    setShowModal(true);
  };

  const getIsEnableAutocomplete = () => {
    if (selectedFilter === DEFAULT_VALUE) {
      return false;
    }
    return true;
  };

  const handleChangeAutocomplete = (value) => {
    if (!getIsEnableAutocomplete()) return null;
    return ordersSearchSchema
      .validate({ selectedFilter, searchValue: value }, { abortEarly: false })
      .then(() => {
        dispatch(setSearchErrors([]));
        dispatch(setSearchValue(value));
        dispatch(setRequestUrl(getSearchRequest(selectedFilter, value)));
      })
      .catch((err) => {
        dispatch(setSearchValue(value));
        dispatch(
          setSearchErrors([
            { field: "searchValue", message: err.inner[0].message },
          ])
        );
      });
  };

  const handleSelectSearched = (value) => {
    if (value === "NO_RESULTS_FOUND") return null;
    const selectedItem = results.find(
      (result) =>
        result?.id?.toString() === value ||
        result?.policy?.toString() === value ||
        result?.person?.toString() === value
    );
    const redirectPath = getRedirectPath(selectedFilter, {
      optionValue: value,
      currentPath: pathname,
      ...selectedItem,
    });
    return window.location.replace(redirectPath);
  };

  useEffect(() => {
    if (
      debouncedSearchValue &&
      debouncedSearchValue.length > 2 &&
      !errors.length
    ) {
      dispatch(getOrdersSearchData());
    } else {
      dispatch(setResults([]));
    }
  }, [debouncedSearchValue]);

  return (
    <>
      <Container>
        <TitleWrapper>
          <IconWrapper>
            <Icon type="appstore" />
          </IconWrapper>
          <Title>{translation("orders.searchBar.title")}</Title>
        </TitleWrapper>
        <SearchWrapper>
          <SearchSelect
            onChange={handleSelectFilter}
            selectOptions={searchFilterOptions}
            value={selectedFilter}
          />
          <FormField error={getErrorMessage("searchValue", errors)}>
            <Autocomplete
              dataSource={dataSource}
              onSelect={handleSelectSearched}
              onSearch={handleChangeAutocomplete}
              disabled={!getIsEnableAutocomplete()}
              name="searchValue"
              placeholder={
                !getIsEnableAutocomplete()
                  ? translation("customer.searchBar.disabledPlaceholder")
                  : translation("customer.searchBar.searchPlaceholder")
              }
              value={searchValue}
              allowClear
            />
          </FormField>
        </SearchWrapper>
        <ActionButton onClick={handleNewOrder}>
          {translation("orders.searchBar.newOrderButtonLabel")}
        </ActionButton>
      </Container>
      <Modal
        closable
        maskClosable
        width={656}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        className="create-order-modal"
      >
        <CreateOrder setShowModal={setShowModal} />
      </Modal>
    </>
  );
};
